import React from "react";
import styled from "@emotion/styled";
import theme from "../theme";

import Navigation from "../components/Navigation.js";
import Footer from "../components/Footer.js";
import NewYork from "../images/city_background.png";

const MainContent = styled.div`
  background-color: ${theme.background};
  padding: 15rem 10%;

  @media (max-width: 1000px) {
    padding: 10rem 8%;
  }
  @media (max-width: 500px) {
    padding: 5rem 8%;
  }
`;

const City = styled.div`
  background-color: ${theme.background};
  padding-bottom: 160px;
  padding-top: 10px;
  background-image: url(${NewYork});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  vertical-align: bottom;
  border-radius: 0px;
  border: 0px;
  z-index: 100;

  @media (max-width: 500px) {
    padding-bottom: 90px;
  }
`;

function Content({ children }) {
  return (
    <div>
      <Navigation />
      <MainContent>{children}</MainContent>
      <City />
      <Footer />
    </div>
  );
}

export default Content;
