import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";
import PhotoAlexis from "../images/people/APS_Stock1crop2.jpg";

function Alexis() {
return (
    <Content>
    <PageContent>
    <h2>Alexis P. Sullivan</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoAlexis} name={"Alexis P. Sullivan"} description={ "Alexis is our Lab Supervisor. She manages the day-to-day operations of our microbiology wet lab and supervises a variety of activities related to the planning and performance of basic and clinical human microbiome research. Her PhD dissertation work at Penn State involved developing novel genomic techniques and integrative analytical approaches to study the ecology and evolution of non-human, non-model organisms in response to human behaviors. While she spent the last few years working with lemurs, lizards, and conch, she has also been a key collaborator for projects focused on human parasites, deep sea corals, Neandertals, marine fishes, and more. Please see her personal website for more information:   " }>
        <a className="extlink" href="https://alexispsullivan.weebly.com/">
        https://alexispsullivan.weebly.com/
    </a>
        </Person>
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Alexis;

