import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { push as Menu } from "react-burger-menu";

import theme from "../theme";

const DesktopMenu = styled.nav`
  background-color: ${theme.background};
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  padding: 1.5rem 10%;
  @media (max-width: 500px) {
    display: none;
  }
`;

const MobileMenu = styled.nav`
  @media (min-width: 500px) {
    display: none;
  }
`;

function Navigation({ className }) {
  return (
    <div>
      <DesktopMenu className={className}>
        <h3>
          <NavLink exact activeClassName="activeLink" to="/">
            research
          </NavLink>
        </h3>
        <h3>
          <NavLink activeClassName="activeLink" to="/people">
            people
          </NavLink>
        </h3>
        <h3>
          <NavLink activeClassName="activeLink" to="/publications">
            publications
          </NavLink>
        </h3>
        <h3>
          <NavLink activeClassName="activeLink" to="/lab">
            lab
          </NavLink>
        </h3>
        <h3>
          <NavLink activeClassName="activeLink" to="/social">
          social
          </NavLink>
          </h3>
          <h3>
          <NavLink activeClassName="activeLink" to="/contact">
            contact
          </NavLink>
        </h3>

        <NavLink
          className="applyButton"
          activeClassName="activeButton"
          to="/apply"
        >
          apply
        </NavLink>
      </DesktopMenu>
      <MobileMenu>
        <Menu>
          <h3>
            <NavLink exact activeClassName="activeLink" to="/">
              research
            </NavLink>
          </h3>
          <h3>
            <NavLink activeClassName="activeLink" to="/publications">
              publications
            </NavLink>
          </h3>
          <h3>
            <NavLink activeClassName="activeLink" to="/people">
              people
            </NavLink>
          </h3>
          <h3>
            <NavLink activeClassName="activeLink" to="/lab">
              lab
            </NavLink>
          </h3>
          <h3>
          <NavLink activeClassName="activeLink" to="/social">
          social
      </NavLink>
          </h3>
          <h3>
            <NavLink activeClassName="activeLink" to="/contact">
              contact
            </NavLink>
          </h3>
          <NavLink
            className="applyButton"
            activeClassName="activeButton"
            to="/apply"
          >
            apply
          </NavLink>
        </Menu>
      </MobileMenu>
    </div>
  );
}

export default Navigation;
