import React, { useState } from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Publication from "../components/Publication.js";

import data from "../data/myarticles.json";

function Publications() {
  const [openPublicationDoi, setOpenPublicationDoi] = useState(
    data[0].identifiers.doi
  );

  const orderedPublicationsList = data.map((publicationData) => (
    <Publication
      {...{ ...publicationData, openPublicationDoi, setOpenPublicationDoi }}
    />
  ));

  return (
    <Content>
      <PageContent>
        <div>
          <h2 className="title">publications</h2>
          {orderedPublicationsList}
        </div>
      </PageContent>
    </Content>
  );
}

export default Publications;
