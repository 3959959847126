import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";
import PhotoWataru from "../images/people/wataru_eastriver_cr.jpg";

function Wataru() {
return (
    <Content>
    <PageContent>
    <h2>Wataru Ebina</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoWataru} name={"Wataru Ebina"} description={ "Wataru is a hematology/oncology fellow and ASCO Young Investigator Awardee. He completed his MD/PhD at Harvard Medical School where worked in the Rossi lab to contribute to diverse projects including the development of chemically modified mRNA as a novel transient protein expression vector, a hematopoietic stem cell reporter mouse, and ex vivo culture conditions for hematopoietic stem cells. He has since completed residency in internal medicine and transitioned to fellowship in hematology/oncology. His current scientific interests lie in developing solid cancer therapeutics inspired by host-microbe interactions.Outside his research, he is cares for patients suffering from a range of cancers at Bellevue Hospital, a safety net hospital, providing healthcare for individuals regardless of their insurance status or ability to pay." } />
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Wataru;

