import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import PhotoWill from "../images/people/will_jogia.jpg";
import Person from "../components/Person.js";

function Will() {
    return (
            <Content>
            <PageContent>
            <h2>William (Will) Jogia</h2>
            <div>
            <div className="flex">
            <Person photo={PhotoWill} name={"Will Jogia"} description={ "Will is a PhD student in the microbiology program. His master’s thesis at McGill University in the Maurice lab implemented old-school microbial ecology techniques in combination with modern sequencing technologies to study the impact of enteric infection on the activity of the murine gut microbiota. In the Schluter lab, Will is interested in bacterial communication between key community members of the human gut, and employs anaerobic culturing methods to uncover changes in gene expression induced by cell-to-cell signaling." } />
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Will;

