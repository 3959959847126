import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";
import PhotoEricNishimoto from "../images/people/eric_nishimoto_square.jpg";

function EricNishimoto() {
return (
    <Content>
    <PageContent>
    <h2>Eric Nishimoto</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoEricNishimoto} name={"Eric Nishimoto"} description={ "Eric is an Assistant Research Technician. He studied at the University of California San Diego and graduated with a degree in Marine Biology. During his undergraduate career, Eric worked in Sandin’s Lab, researching changes in coral community ecology over time. After graduating, he worked in the biotechnology industry for a couple of years and eventually shifted his career toward biomedical research. Eric enjoys folding origami, reading, and walking around the city during his free time."}/>
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default EricNishimoto;

