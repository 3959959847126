import React from "react";
import styled from "@emotion/styled";

import theme from "../theme";

import News from "./News.js";

const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1450px;
  margin: auto;
`;

const SideContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  width: 130px;
  height: 300px;
  margin: 0 2rem;
  margin-left: 5rem;
  top: 5rem;
  background: ${theme.background};
  @media (max-width: 1100px) {
    width: 100px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

function PageContent({ children }) {
  return (
    <PageWrapper>
      <MainContainer>{children}</MainContainer>
      <SideContainer>
        <h2>&nbsp;</h2>
        <News />
      </SideContainer>
    </PageWrapper>
  );
}

export default PageContent;
