import React from "react";
import styled from "@emotion/styled";

import theme from "../theme";

const FooterContainer = styled.div`
  height: 300px;
  width: 100%;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  z-index: -1;
  padding: 1rem;
  background-color: ${theme.tertiary};
  text-align: center;
  color: ${theme.background};
`;

const FooterText = styled.p`
  font-size: 1.3rem;
  padding-top: 5rem;
  color: ${theme.background};
`;

const FooterInfo = styled.p`
  font-size: 1rem;
  color: ${theme.background};
  padding: 0 1rem;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterText>
        <b> The Schluter Lab</b>
        <br />
        Institute for Systems Genetics 
        <br />
        NYU Langone Health
        <br /> Science Building, SB815
        <br />
        435 East 30th Street, New York City
        <br />
        <br />
        jonas.schluter@nyulangone.org &nbsp; &middot; &nbsp; +1-212-263-6973
      </FooterText>
      <FooterInfo className="flex space">
        <i>&copy; 2023 RAMEN LABS</i>
      </FooterInfo>
    </FooterContainer>
  );
}

export default Footer;
