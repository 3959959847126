import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";
import PhotoBarbara from "../images/people/barbara.jpg";

function Barbara() {
return (
    <Content>
    <PageContent>
        <h2>Bárbara M. Da Silva</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoBarbara} name={"Bárbara M. Da Silva"} description={ "Bárbara is an artist who is exploring science surrounding the microbiome. She is currently pursuing a Master's degree at NYU's Gallatin School of Individualized Study. Prior to this, she earned her Bachelor's Degree in Nutrition Sciences from NOVA Medical School in Portugal, while simultaneously advancing her musical education at Hot Clube of Portugal Jazz School. Additionally, her dedication to classical ballet, a passion she has cherished since childhood, remained unwavering. Presently, her focus is on exploring the movements within the body and their impact on individual and collective well-being. This involves studying both internal movements, such as those related to the microbiome and the gut-brain axis, and external movements, including sound, especially the singing voice, and dance." } />
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Barbara;

