import React from "react";
import styled from "@emotion/styled";

import Triangle from "./Triangle.js";

const PaperContainer = styled.div`
  display: flex;
  padding: 10px 0;
`;

const PaperTitle = styled.h5`
  font-weight: normal;
  text-transform: none;
  line-height: 1.5rem;
  cursor: pointer;
  display: inline-block;
`;

const PaperInfo = styled.h6`
  font-weight: bold;
  line-height: 1.5rem;
  display: inline-block;
`;

const PaperAuthors = styled.h6`
  font-size: 1.3rem;
  font-weight: normal;
  font-style: italic;
  line-height: 1.5rem;
`;

const FlexArticle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
  }
`;

const Abstract = styled.p`
  width: 60%;
  padding-right: 5%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const Link = styled.h6`
  text-decoration: "underline";
  @media (max-width: 1100px) {
    width: 100%;
  }
`;


const PressWrapper = styled.h5`
  padding-left: 0;
  line-height: 2rem;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const PressLink = styled.a`
  padding: 0 5px;
  font-weight: 300;
  text-transform: none;
`;

const Image = styled.img`
  padding: 10px;
  border-radius: 5px;
  width: 35%;
  height: 35%;
  @media (max-width: 1100px) {
    width: 50%;
    margin: auto;
  }
  @media (max-width: 500px) {
    width: 100%;
    margin: auto;
  }
`;
const authorToString = (author) => {
  if (author.first_name === "") {
    return author.last_name;
  } else {
    return author.last_name + " " + author.first_name[0];
  }
};
function Publication({
  openPublicationDoi,
  setOpenPublicationDoi,
  abstract,
  art,
  title,
  identifiers,
  source,
  authors,
  year,
  press,
}) {
  const isOpen = openPublicationDoi === identifiers.doi;
  let url = "http://dx.doi.org/" + identifiers.doi;
  let authorsString = authors
    .filter((author) => author.last_name !== "Schluter")
    .map(authorToString)
    .join(", ");

  const hasPress = press && press.length >= 1;

  return (
    <PaperContainer>
      <Triangle
        click={() => {
          if (openPublicationDoi !== identifiers.doi) {
            setOpenPublicationDoi(identifiers.doi);
          } else {
            setOpenPublicationDoi(null);
          }
        }}
        isOpen={isOpen}
      />
      <div key={identifiers.doi}>
        <PaperTitle
          onClick={() => {
            if (openPublicationDoi !== identifiers.doi) {
              setOpenPublicationDoi(identifiers.doi);
            } else {
              setOpenPublicationDoi(null);
            }
          }}
          isOpen={isOpen}
        >
          <PaperInfo className="extlink">
            <a href={url} >
              {year} <i>{source} </i>
            </a>
          </PaperInfo>
          {title}
        </PaperTitle>

        <PaperAuthors>With: {authorsString}</PaperAuthors>
        {isOpen && (
          <FlexArticle>
              <Abstract>
                {abstract}<Link><a href={url}>{'\u2192'} {url}</a></Link>
              {hasPress && (
                <PressWrapper>
                  Press:{" "}
                  {press.map((item) => {
                    return (
                      <PressLink className="extlink" href={item.link}>
                        {item.medium}
                      </PressLink>
                    );
                  })}
                </PressWrapper>
              )}
            </Abstract>
            <Image src={process.env.PUBLIC_URL + `/img/${art}`} />
          </FlexArticle>
        )}
      </div>
    </PaperContainer>
  );
}

export default Publication;
