import React from "react";
import styled from "@emotion/styled";

const Topic = styled.div`
  padding-bottom: 50px;
`;

const Title = styled.h4`
  color: ${(props) => props.color};
`;

const FlexHorizontal = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 4rem;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  width: 50%;
  order: ${(props) => props.orderText};
  @media (max-width: 500px) {
    width: 100%;
    order: 0;
  }
`;

const FlexVertical = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 1rem;
  }
`;

const Articles = styled.div`
  width: 50%;
  padding: 0 3rem;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 5rem;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 1rem;
  }
`;

const Diagram = styled.img`
  width: 50%;
  height: 50%;
  order: ${(props) => props.orderDiagram};

  @media (max-width: 1200px) {
    padding: 3rem;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    padding: 1rem;
    order: 0;
  }
`;

function HomeSection(props) {
  return (
    <Topic id={props.id}>
      <h2>&nbsp;</h2>
      <Title
        color={props.color}
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
        data-aos-anchor-placement="top-bottom"
      >
        {props.title}
      </Title>
      <br />
      <FlexHorizontal>
        <Text
          orderText={props.orderText}
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-easing="ease-in-out"
          data-aos-duration="800"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay="200"
        >
          {props.text}
        </Text>
        <FlexVertical>
          <Articles
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-easing="ease-in-out"
            data-aos-duration="800"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="300"
          >
            <h5>PAPERS</h5>
            <ul>
              <li>
                <a className="extlink" href={props.link1}>
                  {props.year1} <i>{props.article1}</i>
                </a>
              </li>
              <li>
                <a className="extlink" href={props.link2}>
                  {props.year2} <i>{props.article2}</i>
                </a>
              </li>
              <li>
                <a className="extlink" href={props.link3}>
                  {props.year3} <i>{props.article3}</i>
                </a>
              </li>
            </ul>
          </Articles>
          <Diagram
            src={props.photo}
            orderDiagram={props.orderDiagram}
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-easing="ease-in-out"
            data-aos-duration="800"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="400"
          />
        </FlexVertical>
      </FlexHorizontal>
    </Topic>
  );
}

export default HomeSection;
