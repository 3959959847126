import React from "react";
import styled from "@emotion/styled";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";

import Lobby1 from "../images/lab/_EMG1715.jpg";
import Lobby2 from "../images/lab/SB2_Science Lobby Ground Floor View North_Rene Perez.jpg";
import Lobby3 from "../images/lab/school_of_medicine_cut.jpg";
// import Lab1 from "../images/lab/SB6_Science Lab Floor_Rene Perez.jpg";
// import Lab2 from "../images/lab/ScienceBuildingPreview_1335.jpg";
import Lab1 from "../images/lab/chamber.jpg";
import Lab2 from "../images/lab/wetlab1.jpg";
import Lab3 from "../images/lab/chamber_people.jpg";
import Lab4 from "../images/lab/wetlab2.jpg";
import Lab5 from "../images/lab/wetlab3.jpg";
import Room1 from "../images/lab/ScienceBuilding_ConferenceRoomHDR.jpg";
import Room2 from "../images/lab/ScienceBuilding_MeetingRoomHDR.jpg";
import Room3 from "../images/lab/SB7_Science Lab Floor Collaboration Space_Rene Perez.jpg";
import Drylab1 from "../images/lab/drylab1.jpg";
import Drylab2 from "../images/lab/drylab2.jpg";

const LabContainer = styled.section`
margin-bottom: 50px;
`;
const FotoDescription = styled.div`
    @media (max-width: 500px) {
    width: 90%;
    }`;
const FotoBox = styled.div`
display: flex;
flex-wrap: wrap;
`;

const FotoLab = styled.img`
width: 31%;
height: 31%;
margin: 1%;

@media (max-width: 1200px) {
width: 48%;
height: 48%;
}
@media (max-width: 500px) {
width: 90%;
height: 90%;
}
`;

function Lab() {
return ( < Content>
    < PageContent>
    < h2> GALLERY < /h2>
    < FotoDescription>
                < p>
                    We are located in NYU Langone Health's brand new Science Building. Our
                    building encompasses 16 stories and 365,000 square feet, including 10 floors of open and adaptable laboratory space.We are located on the 8th floor where we have plenty of nice dry- and wet-lab space, including a BSL-2 room with a two-person anaerobic chamber. < /p>
    </FotoDescription>
    < LabContainer>
    < h4> Our wet lab space < /h4>
    < FotoBox>
    < FotoLab src={ Lab2 } />
    < FotoLab src={ Lab3 } />
    < FotoLab src={ Lab4 } />
    < FotoLab src={ Lab5 } />
    < FotoLab src={ Drylab1 } />
    < FotoLab src={ Drylab2 } />
    < / FotoBox>
    < /LabContainer>
    < LabContainer>
    < h4> Common spaces < /h4>
    < FotoBox>
    < FotoLab src={ Lobby1 } />
    < FotoLab src={ Lobby2 } />
    < FotoLab src={ Lobby3 } />
    < FotoLab src={ Room1 } />
    < FotoLab src={ Room2 } />
    < FotoLab src={ Room3 } />
    < / FotoBox>
    < /LabContainer>
    < / PageContent>
    < /Content>
);
}

export default Lab;
