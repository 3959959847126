import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import PhotoDamee from "../images/people/DameeMoon.jpg";
import Person from "../components/Person.js";

function Damee() {
    return (
            <Content>
            <PageContent>
            <h2>Damee Moon</h2>
            <div>
            <div className="flex">
            <Person photo={PhotoDamee} name={"Damee Moon"} description={ "Damee is an MD/PhD student at NYU Grossman School of Medicine. She studied at the University of Illinois at Urbana-Champaign, graduating with a major in Molecular and Cellular Biology and minors in Chemistry and Computer Science. Before medical school, Damee worked at the NIH Vaccine Research Center under Danny Douek, exploring adaptive immune responses elicited by Zika virus and SARS-CoV2 vaccines. In her free time, Damee enjoys baking desserts, going to the gym, and exploring new restaurants."}/>
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Damee;

