import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";

import PhotoFanny from "../images/people/fanny2.jpg";
function Fanny() {
return (
    <Content>
    <PageContent>
    <h2>Fanny Matheis</h2>
    <div>
    <div className="flex">
    <Person photo={PhotoFanny} name={"Fanny Matheis"} description={"Fanny is Helen Hay Whitney postdoctoral fellow in the lab. Prior to joining the Schluter lab in 2021, she completed her MD at the Technical University of Munich and her PhD at Rockefeller University in the lab of Daniel Mucida. During her PhD she studied the crosstalk between gut microbes – commensal and pathogenic – and enteric nervous and immune systems. Using gnotobiotic and enteric infection models in mice, her work shed light on the intricate interplay between these diverse components in intestinal homeostasis and uncovered roles of this dialogue in perturbations thereof, including alterations to glucose metabolism and intestinal infections. In the Schluter lab, she plans to develop a systems biology analysis approach to investigate health effects of components of the human microbiome, with an emphasis on homeostasis and neurological diseases."}/>
    </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Fanny;

