import React from "react";
import styled from "@emotion/styled";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Logo1 from "../images/bigdata.svg";
import Logo2 from "../images/experiment.svg";
import Logo3 from "../images/theory.svg";
import theme from "../theme";

const HeaderContainer = styled.div`
  max-width: 1450px;
  margin: auto;
  margin-bottom: 7rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
  }
  @media (max-width: 500px) {
    margin-top: 40px;
  }
`;

const HeaderLeft = styled.div`
  width: 60%;
  text-align: left;

  @media (max-width: 1100px) {
    width: 55%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const WheelLeft = styled.div`
  width: 60%;
  text-align: left;

  @media (max-width: 1100px) {
    width: 55%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const LogoPic = styled.img`
  cursor: pointer;
  width: 15rem;
  border: none;
`;

const TitleContainer = styled.div`
  color: ${theme.text};
`;

const HeaderStyler = styled.b`
  color: ${theme.primary};
`;

const HeaderStylerNYU = styled.b`
  color: #663399;
`;

function Title() {
    return(
            <TitleContainer>
            <h2>
            <HeaderStyler>Microbiome Medicine</HeaderStyler>
            </h2>
            </TitleContainer>
    );
}



function Title2() {
  return(
    <TitleContainer>
          <h1>
          <HeaderStyler>RA</HeaderStyler>tional <HeaderStyler>M</HeaderStyler>icrobiome&nbsp;
          <HeaderStyler>EN</HeaderStyler>gineering labs
      </h1>
    </TitleContainer>
  );
}



function Header() {
  return (
    <HeaderContainer>
      <HeaderLeft>
        <div>
          <Title />
        </div>
        <p>
          Since its emergence, multicellular life has evolved in presence of microorganisms. Therefore, microbial life has shaped how our bodies function. In the gut microbiome, trillions of microbes form an ecosystem that can provide benefits or cause harm, and represents tantalizing opportunities for future therapies. We are an interdisciplinary team of researchers working towards a new type of medicine that leverages the central role of microbes for our health: <i>Microbiome Medicine</i>.
        </p>
        <p>
      We are tackling three major challenges: 1) We are identifying causal health effects originating from the microbial ecosystem using ultra-high resolution multi-modal data and computational learning techniques. 2) We are dissecting evolutionary mechanisms that determine microbiome behaviors by pairing game theory with transgenerational gnotobiotic experiments. 3) We are developing novel strategies to manipulate microbial ecosystems, focusing on repurposing evolved microbial warfare strategies, to develop safe drug candidates for human trials.
</p>
        <p>
Our group is led by Jonas Schluter. We are located in the new Science Building of NYU Langone Health, one of the top 5 medical schools in the US. We are always looking for enthusiastic new team members and collaborators. Please read more on our research here and do not hesitate to contact us.
        </p>
      </HeaderLeft>
      <div className="ferris-wrapper">
        <div className="wheel">
          <div className="flex center">
            <AnchorLink className="cabin" href="#One">
              <LogoPic src={Logo1} />
            </AnchorLink>
          </div>
          <div className="flex space">
            <AnchorLink className="cabin" href="#Two">
              <LogoPic src={Logo2} />
            </AnchorLink>
            <AnchorLink className="cabin" href="#Three">
              <LogoPic src={Logo3} />
            </AnchorLink>
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
}

export default Header;
