import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import DefaultFemale from "../images/people/Person2.png";
import Person from "../components/Person.js";
import PhotoNora from "../images/people/Nora_website.jpg";

function Nora() {
    return (
            <Content>
            <PageContent>
            <h2>Nora Pyenson</h2>
            <div>
            <div className="flex">
        <Person photo={PhotoNora} name={"Nora Pyenson"} description={ "Nora is an LSRF postdoctoral fellow. During her PhD in Luciano Marraffini’s lab she wrangled with the (cooler but less famous) CRISPR systems found in bacteria, which serve as the prokaryotic adaptive immune system. There she got into the nitty gritty molecular details of CRISPR, studying the interplay between phage evolution and the flexibility/specificity of the immune response. Nora moseyed up to New Haven, Connecticut for a postdoc under Alvaro Sanchez and Paul Turner at Yale University. There she was charmed by the lush world of ecology and evolutionary biology. To help explore the origins of phage diversity in nature she developed a system for convening little communities of phages that coexist on a single host population. In the Schluter lab Nora will continue pestering her bacteria with phages, with a focus lasered towards bugs involved in human health. See more at:"}>
        <a className="extlink" href="https://norapyenson.com/">
        https://norapyenson.com/
    </a>
        </Person>
            </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Nora;

