import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person, {PreviousPersonNoPic, } from "../components/Person.js";

import PhotoJonas from "../images/people/jonas24bwcloser.png";
import PhotoGrant from "../images/people/Grant Hussey5.jpg";
import PhotoRene from "../images/people/rene.jpg";
import PhotoAngel from "../images/people/anqi_dai.jpg";
import PhotoAlexis from "../images/people/APS_Stock1crop2.jpg";
import PhotoLily from "../images/people/lily_cropped.jpg";
import PhotoBrian from "../images/people/BrianChirn3.jpg";
import PhotoCaichen from "../images/people/CaichenDuan.jpg";
import PhotoWill from "../images/people/will_jogia.jpg";
import PhotoKarina from "../images/people/KarinaFlores.jpg";
import PhotoFanny from "../images/people/FannyMatheis.jpg";
import PhotoHershy from "../images/people/HershyMaan.jpg";
import PhotoKimberly from "../images/people/KimberlyZaldana.jpg";
import PhotoDamee from "../images/people/DameeMoon.jpg";
import PhotoShiney from "../images/people/ShineyChandraganti.jpg";
import PhotoJoseph from "../images/people/JosephKim.png";
import PhotoNora from "../images/people/Nora_website.jpg";
import PhotoWataru from "../images/people/wataru_eastriver_cr.jpg";
import PhotoBarbara from "../images/people/barbara.jpg";
import PhotoEricNishimoto from "../images/people/eric_nishimoto_square.jpg";
import PhotoDipta from "../images/people/dipta_cr.jpg";
import DefaultMale from "../images/people/Person1.png";
import DefaultFemale from "../images/people/Person2.png";

function People() {
return (
    <Content>
    <PageContent>
    <h2>our team</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoJonas} name={"Jonas Schluter, PhD"} description={ "Jonas is tenure-track Assistant Professor at the Institute for Systems Genetics and Department for Microbiology at NYU Langone Health. With a background in microbial biotechnology and mathematical biology, he joined the lab of Kevin Foster at Oxford University. During his PhD, he developed ecological and evolutionary models of the gut microbiota, and built computer simulations of gut epithelium-associated microbial populations. He studied game-theory as an independent JSPS research fellow in Hisashi Ohtsuki and Akira Sasaki's group at Sokendai in Japan to investigate evolutionary conflicts in the microbiome. He then joined Joao Xavier's group at Memorial Sloan Kettering Cancer Center in New York City as a Research Scholar. At MSKCC, he studied how the gut microbiota influences the health and immune system of cancer patients, leveraging vast clinical data sets and interventional trials. He is the Principal Investigator of our team." } />
        <Person photo={PhotoAlexis} name={"Alexis P. Sullivan, PhD"} description={ "Alexis is our Lab Supervisor. She manages the day-to-day operations of our microbiology wet lab and supervises a variety of activities related to the planning and performance of basic and clinical human microbiome research. Her PhD dissertation work at Penn State involved developing novel genomic techniques and integrative analytical approaches to study the ecology and evolution of non-human, non-model organisms in response to human behaviors. While she spent the last few years working with lemurs, lizards, and conch, she has also been a key collaborator for projects focused on human parasites, deep sea corals, Neandertals, marine fishes, and more. Please see her personal website for more information:   " }>
    <a className="extlink" href="https://alexispsullivan.weebly.com/">
    https://alexispsullivan.weebly.com/
    </a>
        </Person>
        <Person photo={PhotoBarbara} name={"Bárbara M. Da Silva"} description={ "Bárbara is an artist who is exploring science surrounding the microbiome. She is currently pursuing a Master's degree at NYU's Gallatin School of Individualized Study. Prior to this, she earned her Bachelor's Degree in Nutrition Sciences from NOVA Medical School in Portugal, while simultaneously advancing her musical education at Hot Clube of Portugal Jazz School. Additionally, her dedication to classical ballet, a passion she has cherished since childhood, remained unwavering. Presently, her focus is on exploring the movements within the body and their impact on individual and collective well-being. This involves studying both internal movements, such as those related to the microbiome and the gut-brain axis, and external movements, including sound, especially the singing voice, and dance." } />
        <Person photo={PhotoCaichen} name={"Caichen Duan"} description={ "Caichen is a PhD student in the NYU Tandon School of Engineering. She is working on Quorum Sensing to explore bacterial communication in the gut microbiome. For this, she is constructing dedicated bioinformatics pipelines and large microbiome data sets. Her aim is to better understand the sociality among gut bacteria. In addition, Caichen is interested in how antibiotics may affect bacterial communications in variable gut microbiota ecosystems. " } />
        <Person photo={PhotoWataru} name={"Wataru Ebina, MD/PhD"} description={ "Wataru is a hematology/oncology fellow and ASCO Young Investigator Awardee. He completed his MD/PhD at Harvard Medical School where worked in the Rossi lab on the development of modified mRNA as a novel transient protein expression vector, a hematopoietic stem cell reporter mouse, and ex vivo culture conditions for hematopoietic stem cells. He has since completed residency in internal medicine and transitioned to fellowship in hematology/oncology. His scientific interests lie in developing cancer therapeutics inspired by host-microbe interactions. Outside his research, he is cares for patients suffering from a range of cancers at Bellevue Hospital, a safety net hospital, providing healthcare for individuals regardless of their insurance status or ability to pay." } />
        <Person photo={PhotoWill} name={"Will Jogia"} description={ "Will is a PhD student in the microbiology program. His master’s thesis at McGill University in the Maurice lab implemented old-school microbial ecology techniques in combination with modern sequencing technologies to study the impact of enteric infection on the activity of the murine gut microbiota. In the Schluter lab, Will is interested in bacterial communication between key community members of the human gut, and employs anaerobic culturing methods to uncover changes in gene expression induced by cell-to-cell signaling." } />
        <Person photo={PhotoHershy} name={"Hershy Maan, PhD"} description={"Hershy is a postdoctoral fellow. He completed his PhD at the Weizmann Institute of Science, Israel, in the lab of Ilana Kolodkin-Gal. His research focused on systematically evaluating the role of non-ribosomal peptides/polyketide (NRPs/PKS) antibiotics produced by Bacillus subtilis, and how these antibiotics are crucial during interspecies competition. Furthermore, his work also revealed how biofilm formation is essential to regulate these antibiotics during interspecies competition. In the Schluter, he is studying the interspecies interactions between different gut bacteria in vitro. Hershy wants to understand how these interactions can be influenced by various factors like nutrient availability, and alter phenotypes such as biofilm formation and quorum sensing release. Besides spending time with his beloved bacteria, he likes exploring the city and watching some real football, aka soccer."}/>
        <Person photo={PhotoFanny} name={"Fanny Matheis, MD/PhD"} description={"Fanny is a Helen Hay Whitney postdoctoral fellow in the lab. Prior to joining the Schluter lab in 2021, she completed her MD at the Technical University of Munich and her PhD at Rockefeller University in the lab of Daniel Mucida. During her PhD she studied the crosstalk between gut microbes – commensal and pathogenic – and enteric nervous and immune systems. Using gnotobiotic and enteric infection models in mice, her work shed light on the intricate interplay between these diverse components in intestinal homeostasis and uncovered roles of this dialogue in perturbations thereof, including alterations to glucose metabolism and intestinal infections. In the Schluter lab, she plans to develop a systems biology analysis approach to investigate health effects of components of the human microbiome, with an emphasis on homeostasis and neurological diseases."}/>
        <Person photo={PhotoDamee} name={"Damee Moon"} description={ "Damee is an MD/PhD student at NYU Grossman School of Medicine. She studied at the University of Illinois at Urbana-Champaign, graduating with a major in Molecular and Cellular Biology and minors in Chemistry and Computer Science. Before medical school, Damee worked at the NIH Vaccine Research Center under Danny Douek, exploring adaptive immune responses elicited by Zika virus and SARS-CoV2 vaccines. In her free time, Damee enjoys baking desserts, going to the gym, and exploring new restaurants."}/>
        <Person photo={PhotoEricNishimoto} name={"Eric Nishimoto"} description={ "Eric is an Assistant Research Technician. He studied at the University of California San Diego and graduated with a degree in Marine Biology. During his undergraduate career, Eric worked in Sandin’s Lab, researching changes in coral community ecology over time. After graduating, he worked in the biotechnology industry for a couple of years and eventually shifted his career toward biomedical research. Eric enjoys folding origami, reading, and walking around the city during his free time."}/>
        <Person photo={PhotoNora} name={"Nora Pyenson, PhD"} description={ "Nora is an LSRF postdoctoral fellow. During her PhD in Luciano Marraffini’s lab she wrangled with the (cooler but less famous) CRISPR systems found in bacteria, which serve as the prokaryotic adaptive immune system. There she got into the nitty gritty molecular details of CRISPR, studying the interplay between phage evolution and the flexibility/specificity of the immune response. Nora moseyed up to New Haven, Connecticut for a postdoc under Alvaro Sanchez and Paul Turner at Yale University. There she was charmed by the lush world of ecology and evolutionary biology. To help explore the origins of phage diversity in nature she developed a system for convening little communities of phages that coexist on a single host population. In the Schluter lab Nora will continue pestering her bacteria with phages, with a focus lasered towards bugs involved in human health. See more at:"}>
        <a className="extlink" href="https://norapyenson.com/">
        https://norapyenson.com/
    </a>
        </Person>
        <Person photo={PhotoDipta} name={"Dipta Paul"} description={ "Dipta is a Master's student in Biomedical Engineering at NYU Tandon School of Engineering. In our lab, Dipta is working on identifying mechanisms of microbial community ecology, with the hope to use them therapeutically. During his undergraduate studies at the University of South Florida, he served as a research assistant, where he helped formulate a novel probiotic fermented drink aimed at alleviating the onset of Alzheimer's disease. Prior to beginning his master's program, he worked as an assistant scientist at Thermo Fisher Scientific for six months. In his free time, he is passionate about soccer, both watching games and playing whenever he gets the chance."}/>
        <Person photo={PhotoLily} name={"Chenzhen Lily Zhang"} description={ "Lily is a PhD candidate in the Systems and Computational Biomedical training program at Vilcek Institute of Biomedical Sciences. Prior to joining the Schluter lab, Lily graduated from Rutgers University with a major in Molecular Biology and Biochemistry and a minor in Computer Science. Her current research interest lies in understanding the interaction between gut microbiome and immune system via both theoretical and experimental approaches. During her free time, Lily enjoys watching movies, playing music, and hiking." } />
        <Person photo={PhotoAngel} name={"Anqi (Angel) Dai"} description={ "Angel studied bioinformatics for her Masters degree at Boston University. She is currently working at Memorial Sloan Kettering Cancer Center as a computational biologist in the laboratory of Dr. Marcel van den Brink, analyzing the composition of the bacterial communities in biological specimens and relating this composition to clinical outcomes of patients with cancer. She is interested in identifying key factors that contribute to a healthier microbiota among transplant patients. Dr. Peled at MSK and Dr. Schluter at NYU are currently supervising her main research project where she uses detailed records of patients’ diets to estimate the effects of different food types on the bacterial ecosystem in the gut." } />

    </div>
    <div>
        <PreviousPersonNoPic photo={DefaultFemale} name={"Sophie Tanenbaum (volunteer)"}/>
        <PreviousPersonNoPic photo={PhotoJoseph} name={"Sigri Kløve (visiting student)"}/>
        <PreviousPersonNoPic photo={PhotoJoseph} name={"Emily Dembinsky (rotation student)"}/>
        <PreviousPersonNoPic photo={PhotoJoseph} name={"Joseph Jae-Suk Kim (rotation student)"}/>
        <PreviousPersonNoPic photo={PhotoGrant} name={"Grant A. Hussey (grad student)"}/>
        <PreviousPersonNoPic photo={PhotoBrian} name={"Brian Chirn (grad student)"}/>
        <PreviousPersonNoPic photo={PhotoShiney} name={"Shiney Chandragati (volunteer)"}/>
        <PreviousPersonNoPic photo={PhotoKimberly} name={"Kim Zaldana (rotation student)"}/>
        <PreviousPersonNoPic photo={PhotoKarina} name={"Karina Flores (rotation student)"}/>
    </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default People;
