import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import PhotoHershy from "../images/people/HershyMaan.jpg";
import Person from "../components/Person.js";

function Hershy() {
    return (
            <Content>
            <PageContent>
            <h2>Harsh (Hershy) Maan</h2>
            <div>
            <div className="flex">
            <Person photo={PhotoHershy} name={"Hershy Maan"} description={"Hershy is a postdoctoral fellow. He completed his PhD at the Weizmann Institute of Science, Israel, in the lab of Ilana Kolodkin-Gal. His research focused on systematically evaluating the role of non-ribosomal peptides/polyketide (NRPs/PKS) antibiotics produced by Bacillus subtilis, and how these antibiotics are crucial during interspecies competition. Furthermore, his work also revealed how biofilm formation is essential to regulate these antibiotics during interspecies competition. In the Schluter, he is studying the interspecies interactions between different gut bacteria in vitro. Hershy wants to understand how these interactions can be influenced by various factors like nutrient availability, and alter phenotypes such as biofilm formation and quorum sensing release. Besides spending time with his beloved bacteria, he likes exploring the city and watching some real football, aka soccer."}/>
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Hershy;

