import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";
import PhotoCaichen from "../images/people/CaichenDuan.jpg";

function Caichen() {
return (
    <Content>
    <PageContent>
    <h2>Caichen Duan</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoCaichen} name={"Caichen Duan"} description={ "Caichen is a PhD student in the NYU Tandon School of Engineering. She is working on Quorum Sensing to explore bacterial communication in the gut microbiome. For this, she is constructing dedicated bioinformatics pipelines and large microbiome data sets. Her aim is to better understand the sociality among gut bacteria. In addition, Caichen is interested in how antibiotics may affect bacterial communications in variable gut microbiota ecosystems. " } />
    </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Caichen;

