import React from "react";
import styled from "@emotion/styled";

import theme from "../theme";

const TriangleBox = styled.div`
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 20px solid ${theme.primary};
  border-bottom: 10px solid transparent;
  margin: 10px;
  animation: ${(props) => (props.isOpen ? "rotateOpen" : "rotateClose")}
    forwards 0.5s;

  @keyframes rotateOpen {
    0% {
      transform: rotate(0deg);
    }
    100% {
      border-left: 20px solid ${theme.primaryLight};
      transform: rotate(90deg);
    }
  }

  @keyframes rotateClose {
    0% {
      border-left: 20px solid ${theme.primaryLight};
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

function Triangle({ click, isOpen }) {
  return (
    <div onClick={click}>
      <TriangleBox isOpen={isOpen}></TriangleBox>
    </div>
  );
}

export default Triangle;
