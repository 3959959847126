import React from "react";
import styled from "@emotion/styled";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";

const ContactContainer = styled.div`
  width: 90%;
`;

function Contact() {
  return (
    <Content>
      <PageContent>
        <h2>contact us</h2>
        <ContactContainer>
          <h4>Email</h4>
          <p>jonas.schluter@nyulangone.org</p>
        </ContactContainer>
        <ContactContainer>
          <h4>Phone</h4>
          <p>+1-212-263-6973</p>
        </ContactContainer>
        <ContactContainer>
          <h4>Address</h4>
          <p>
            The Schluter Lab <br />
            Institute for Systems Genetics
            <br />
            NYU Langone Health
            <br /> Science Building, SB815
            <br />
            435 East 30th Street, New York City
          </p>
        </ContactContainer>
      </PageContent>
    </Content>
  );
}

export default Contact;
