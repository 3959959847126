import React from "react";
import styled from "@emotion/styled";

const PersonContainer = styled.div`
  display: inline-block;
  width: 40%;
  margin: 0 5% 10% 5%;

  @media (max-width: 1100px) {
    width: 46%;
    margin: 0 2% 10% 2%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;

const PreviousPersonContainer = styled.div`
  display: inline-block;
  width: 25%;
  margin: 0 2.5% 5% 2.5%;

  @media (max-width: 550px) {
    width: 46%;
    margin: 0 2% 10% 2%;
  }
  @media (max-width: 250px) {
    width: 90%;
  }
`;


const Photo = styled.img`
  border-radius: 100px;
  width: 50%;
  margin: 5% auto;
  border: none;
`;

const PhotoPrevious = styled.img`
  border-radius: 100px;
  width: 20%;
  margin: 5% auto;
  border: none;
`;

const Name = styled.h4`
  border-bottom: 2px solid black;
  padding: 0rem;
  text-align: left;
`;

const PreviousName = styled.h5`
  border-bottom: 2px solid black;
  padding: 0rem;
  text-align: left;
  text-transform: capitalize;
  font-size: 1.5em;
`
;const Button = styled.button`
  color: ${props =>
    props.primary ? 'hotpink' : 'turquoise'};
`;

// const Role = styled.text`
//   color: ${props => props.color ? 'black' : '#B23951'};
//   padding: 0rem;
//   text-align: left;
// `;


const Description = styled.p`
  padding: 2rem 1rem;
  text-align: left;
`;

function Person(props) {
  return (
    <PersonContainer>
      <Photo src={props.photo} />
          <Name>{props.name}</Name>
      <Description>
        {props.description}
        {props.children}
      </Description>
    </PersonContainer>
  );
}

export function PreviousPerson(props) {
    return (
      <PreviousPersonContainer>
        <PhotoPrevious src={props.photo} />
        <PreviousName>Previous member: {props.name}</PreviousName>
      </PreviousPersonContainer>
    );
}

export function PreviousPersonNoPic(props) {
    return (
            <PreviousPersonContainer>
            <PreviousName>Previous member: {props.name}</PreviousName>
            </PreviousPersonContainer>
    );
}


export default Person;
