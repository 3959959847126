const theme = {
  primary: "#3B8BEB",
  secondary: "#B23951",
  tertiary: "#8590AA",
  background: "#fcfafa",
  text: "#4F5666",
  primaryLight: "#C4DBF6",
  primaryVeryLight: "#E1ECFA",
  alarm: "#ff0000",
  alarm2: "#800000",
};

export default theme;
