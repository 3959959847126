import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";
import Person from "../components/Person.js";
import PhotoDipta from "../images/people/dipta_cr.jpg";

function Dipta() {
return (
    <Content>
    <PageContent>
    <h2>Dipta Paul</h2>
    <div>
    <div className="flex">
        <Person photo={PhotoDipta} name={"Dipta Paul"} description={ "Dipta is a Master's student in Biomedical Engineering at NYU Tandon School of Engineering. In our lab, Dipta is working on identifying mechanisms of microbial community ecology, with the hope to use them therapeutically. During his undergraduate studies at the University of South Florida, he served as a research assistant, where he helped formulate a novel probiotic fermented drink aimed at alleviating the onset of Alzheimer's disease. Prior to beginning his master's program, he worked as an assistant scientist at Thermo Fisher Scientific for six months. In his free time, he is passionate about soccer, both watching games and playing whenever he gets the chance."}/>
        </div>
    <br />
    </div>
    </PageContent>
    </Content>
);
}

export default Dipta;

