import React from "react";

import Content from "../components/Content.js";
import PageContent from "../components/PageContent.js";

function Contact() {
  return (
    <Content>
      <PageContent>
        <h2>Positions available!</h2>

        <h4>
          Post-doc (experimental): Exploring evolutionary and ecosystems
          strategies to control complex microbial communities
        </h4>
        <p>
          How can we exert control over the gut microbiome? We are looking to
          hire a postdoc with experience in anaerobic cultivation techniques to
          work on an <i>in vitro</i> model system of human gut microbes. In particular,
          we are interested in identifying the principles of microbial warfare
          that influence the dynamics of the gut ecosystem. Many microbial
          traits can interfere with the growth of competing species. We want to
          understand their potential to control community
          dynamics. Once characterized <i>in vitro</i>, we will seek to test how such
          traits can be used <i>in vivo</i> to shift the gut ecosystem. The project will rely heavily on transcriptomics, and allow you to gain experience in advanced time series data analyses methods.
        </p>
        <h6>
          <a
            href="mailto:jonas.schluter@nyulangone.org?subject=Post-doc%20(experimental)"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply
          </a>
        </h6>
        <br />
        <br />

        <h4>
          Post-doc (big data): Identifying causal effects of the human gut
          microbiome on health using time series data and electronic health
          records
        </h4>
        <p>
          We recently published one of the largest longitudinal microbiota data
          sets in the world and paired this with vast amounts of clinical and
          other metadata obtained from electronic health records. We are only
          beginning to tap into this tremendous resource and the vast data
          treasures contained in EHRs. Data from thousands of patients collected
          over many years offer completely new ways of understanding health,
          homeostasis, and medicine. We are looking to hire a postdoctoral
          researcher to develop new causal inference techniques and time series
          analysis methods using our massive data resource. This project will
          include working on dynamic systems theory as well as Bayesian
          inference and machine learning, with the goal of identifying important
          health effects of the human gut microbiome and targets for rational
          microbiome engineering.
        </p>
        <h6>
          <a
            href="mailto:jonas.schluter@nyulangone.org?subject=Post-doc%20(big%20data)"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply
          </a>
        </h6>
        <br />
        <br />

        <h4>Post-doc (theory): Ecology and evolution of the microbiome</h4>
        <p>
          We have an established computer simulation framework to simulate
          ecological and evolutionary dynamics in the human gut microbiome. We
          want to understand the game-theoretical underpinnings that drive the
          dynamics observed in host associated microbial communities. Our bodies
          have evolved amazing solutions to the challenge of exerting control
          over vicious, fast growing microbial ecosystems. Fundamental
          understanding of these solutions may teach us to learn from our own
          bodies how to become better microbiota engineers.
        </p>
        <h6>
          <a
            href="mailto:jonas.schluter@nyulangone.org?subject=Post-doc%20(theory)"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply
          </a>
        </h6>
        <br />
        <br />
          <h4>Lab Technician</h4>
          <p>
          We are looking to hire an experienced microbiologist as a lab technician.
          Under direction, the technician provides operational support for our human microbiome research laboratory. This includes performing experiments in our anaerobic microbial cultivation facilities, human sample preparation, and supporting our germ-free mouse experiments.          </p>
          <h6>
          <a
      href="mailto:jonas.schluter@nyulangone.org?subject=Tech"
      target="_blank"
      rel="noopener noreferrer"
          >
          Apply
      </a>
          </h6>
          <br />
          <br />


        <h5>For more information and to apply email at:</h5>
        <h6>jonas.schluter@nyulangone.org</h6>
      </PageContent>
    </Content>
  );
}

export default Contact;
